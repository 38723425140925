import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { fasIcon } from "h";
import { SubmitButton } from "checkout/form";
import styles from "./verification.module.sass";

function BackButton({ onBack, text }) {
  return (
    <button type="button" onClick={onBack} className="btn-link left-button">
      {text ? (
        text
      ) : (
        <Fragment>
          {fasIcon("chevron-left")} {" Back"}
        </Fragment>
      )}
    </button>
  );
}

function SkipButton({ onSkip }) {
  if (onSkip) {
    return (
      <button type="button" onClick={onSkip} className="btn-link left-button">
        Skip
      </button>
    );
  } else {
    return null;
  }
}

function VerificationCodeControls(props) {
  const [resendClicked, setResetClicked] = useState(false);
  const { form, onBack, backText } = props;
  const onResend = debounce(
    () => {
      setResetClicked(true);
      props.onResend();
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );
  return (
    <div className={`fieldgroup buttons ${styles.controls}`}>
      <BackButton onBack={onBack} text={backText} />
      <SkipButton onSkip={props.onSkip} />
      <button type="button" onClick={onResend} className="btn-link form_cancel">
        {resendClicked ? "Code Sent" : "Resend Code"}
      </button>
      <SubmitButton form={form} text="Confirm Code" />
    </div>
  );
}

VerificationCodeControls.propTypes = {
  form: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  backText: PropTypes.string,
};

export default VerificationCodeControls;
