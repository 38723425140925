import React, { useState, useRef, useEffect } from "react";
import range from "lodash/range";
import styles from "./verification.module.sass";

function VerificationCodeInput(props) {
  const [code, setCode] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const inputRef = useRef([]);
  const addCharacters = (e) => {
    const chars = e.target.value;
    const newCode = (code + chars).replace(/\D/g, "").substring(0, 6);
    setCode(newCode);
  };

  const removeCharacter = (e) => {
    if (e.keyCode === 8) {
      setCode(code.slice(0, -1));
    }
  };

  useEffect(() => {
    setActiveIndex(getActiveIndex(code));
    props.updateCode(code);
  }, [code]);

  const setFocus = () => {
    inputRef.current[activeIndex].focus();
  };

  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      setFocus();
    } else {
      firstRender.current = false;
    }
  }, [activeIndex]);
  return (
    <div className={styles.verification_input} onClick={() => setFocus()}>
      {range(0, 3).map((i) => (
        <input
          className={styles.input}
          key={i}
          type="text"
          inputMode="numeric"
          value={code[i] || ""}
          ref={(el) => (inputRef.current[i] = el)}
          disabled={i !== activeIndex}
          onKeyDown={removeCharacter}
          onChange={addCharacters}
          name={`code[${i}]`}
          id={`code[${i}]`}
        />
      ))}
      <div className={styles.spacer}>-</div>
      {range(3, 6).map((i) => (
        <input
          className={styles.input}
          key={i}
          type="text"
          inputMode="numeric"
          value={code[i] || ""}
          ref={(el) => (inputRef.current[i] = el)}
          disabled={i !== activeIndex}
          onKeyDown={removeCharacter}
          onChange={addCharacters}
          name={`code[${i}]`}
          id={`code[${i}]`}
        />
      ))}
    </div>
  );
}

function getActiveIndex(code) {
  const length = code.length;

  return length === 6 ? 5 : length;
}

export default VerificationCodeInput;
