import { xhrPost } from "common/xhr";

/**
 *
 * @param {Object} params - the request parameters
 * @param {string} params.email - the email address
 * @param {string} params.username - the username
 * @returns {Promise} result - the result of the request
 */
 export async function sendVerificationEmailNew(params) {
  try {
    await xhrPost("/email_verifications/send_verification_email_new", params);
  } catch (error) {
    return Promise.reject(error.errors);
  }
}

/**
 *
 * @param {Object} params - the request parameters
 * @param {string} params.email - the email address
 * @returns {Promise} result - the result of the request
 */
export async function sendVerificationEmailEdit(params) {
  try {
    await xhrPost("/email_verifications/send_verification_email_edit", params);
  } catch (error) {
    return Promise.reject(error.errors);
  }
}

/**
 *
 * @param {Object} params - the request parameters
 * @param {string} params.email - the email address
 * @returns {Promise} result - the result of the request
 */
export async function resendVerificationEmail(params) {
  try {
    await xhrPost("/email_verifications/resend_verification_email", params);
  } catch (error) {
    return Promise.reject(error.errors);
  }
}

/**
 *
 * @param {Object} params - the request parameters
 * @param {string} params.email - the email address
 * @param {string} params.code - the verification code
 * @returns {Promise} result - the result of the request
 */
export async function verifyEmail(params) {
  try {
    await xhrPost("/email_verifications/verify_email", params);
  } catch (error) {
    return Promise.reject(error.errors);
  }
}
