import React from "react";
import c from "classnames";
import styles from "./verification.module.sass";

function VerificationHolder(props) {
  return (
    <div className={c("fieldgroup", props.className)}>
      <div className={styles.verification_holder}>{props.children}</div>
    </div>
  );
}

export default VerificationHolder;
