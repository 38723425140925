import React, { Fragment } from "react";
import styles from "./verification.module.sass";

function VerificationMessage({ email, type="a verification" }) {
  return (
    <Fragment>
      <p className={styles.message}>
        We sent {type} code to <span className="bold">{email}</span>
      </p>
      <p className={styles.message}>Please enter the code below.</p>
    </Fragment>
  );
}

export default VerificationMessage;
