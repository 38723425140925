import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styles from "./verification.module.sass";

function VerificationError({ formError }) {
  return (
    <TransitionGroup>
      {formError && (
        <CSSTransition
          classNames="form-error"
          timeout={{
            enter: 500,
            exit: 500,
          }}
        >
          <div className={styles.form_error}>
            {Object.values(formError).join("")}
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}


export default VerificationError;
